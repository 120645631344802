export class App {

    constructor() {

    }

    public initiate() {

    }
}

let app = new App();
app.initiate();
